import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { RiArrowGoBackLine } from "react-icons/ri"
import { Disqus, CommentCount } from "gatsby-plugin-disqus"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  let disqusConfig = {
    identifier: frontmatter.slug,
    title: frontmatter.title,
  }
  return (
    <Layout>
      <SEO title={frontmatter.title} description={frontmatter.description} />
      <div className="blog-post-container">
        <div className="blog-post">
          <h3
            style={{
              textAlign: "right",
              margin: "0 auto 3rem auto",
              fontWeight: "normal",
            }}
          >
            {frontmatter.date}
          </h3>
          <h2
            style={{
              fontSize: "2rem",
              textAlign: "center",
              margin: "0 auto 3rem auto",
            }}
          >
            {frontmatter.title}
          </h2>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
        <div className="disqus-box">
          <CommentCount className="disqus-extended" config={disqusConfig} />
        </div>
        <Disqus config={disqusConfig} />
      </div>
      <div style={{ textAlign: "center", margin: "0 0 2rem 0" }}>
        <Link to="/" style={{ fontSize: "4rem", color: "#831911" }}>
          <RiArrowGoBackLine />
        </Link>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "DD.MM.YYYY")
        slug
        title
        description
        tags
      }
    }
  }
`
